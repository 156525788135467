.Categorybg{
    background-color: #333;
    padding: 20px 0px;
}

.category-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #333;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.5s;
    position: relative;
    z-index: 100;
    background-image: linear-gradient(90deg, #FFD700 50%, #333 50%);
    background-size: 200% 100%;
    background-position: 100% 0;
    border: 2px solid #FFD700;
    border-radius: 4px;
}

.category-card:hover{
    background-position: 0 0;
    color: #333;

}

.category-card img {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
}
